<script lang="ts" setup>
import type { HomeBanner } from "~/types";
const { t } = useT();
const { open } = useAppModals();

const { allGames } = useHomePage();

const { data: appInit } = useAppInitData();

const { isOneClickRegister } = useOtpGetFlow();
const { handleOpenGame } = useOpenGame(open);

const props = defineProps<{ banner: HomeBanner }>();

const findGame = (slug: string) => allGames.value?.find((el) => el.slug === slug.replace("game=", "") || {});
const router = useRouter();

const buttonClick = () => {
	if (appInit.value?.isGuest || props.banner.buttonLink === "/register/") {
		if (isOneClickRegister) {
			open("LazyOModalOneClickSignup");
			return;
		}
		open("LazyOModalSignup");
		return;
	}

	if (!props.banner.buttonLink) {
		return;
	}

	if (props.banner.buttonLink.includes("facebook")) {
		window.open(props.banner.buttonLink, "_blank");
		return;
	}

	if (props.banner.buttonLink.includes("game=")) {
		const slug = findGame(props.banner.buttonLink)?.slug;
		handleOpenGame(slug);
		return;
	}
	router.push(props.banner.buttonLink);
};
</script>
<template>
	<MBanner
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		type="register"
		@click="buttonClick"
	>
		<template #default>
			<AText variant="taylor">
				<span class="slide-item__header" v-html="banner?.title" />
			</AText>
		</template>
		<template #description>
			<AText variant="tunja" :modifiers="['bold']">
				<span class="slide-item__description" v-html="banner?.smallTitle" /> </AText
		></template>
		<template #actions>
			<div class="actions">
				<AAnimationCustomByScroll animationClass="blink" transitionDelay="1s">
					<AButton variant="primary" class="app-banner__btn" size="xl">
						<AText variant="turin" :modifiers="['medium']">{{ banner.buttonText }}</AText>
						<ASvg name="24/play" class="icon" />
					</AButton>
				</AAnimationCustomByScroll>
			</div>
			<div class="rate-wrapper">
				<AText variant="toledo">{{ t("Our champions love us") }}</AText>
				<OBannersHeroRate />
			</div>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
:deep(.app-banner__content) {
	justify-content: flex-start;
	padding: 32px 24px;

	@include media-breakpoint-down(sm) {
		height: 372px;
		top: 200px;
		padding: 24px 12px;
		align-items: flex-start;
		width: calc(100% - 32px);
		margin: 0 auto;
		border-radius: 16px;
		border: 2px solid var(--chifeng);
		background: var(--czestochowa);
	}

	.app-banner__desc span {
		@include media-breakpoint-down(sm) {
			font-size: 32px;
		}
	}
}
.logo {
	display: flex;
	justify-content: center;
	margin-bottom: gutter(2);

	@include media-breakpoint-down(sm) {
		margin-bottom: gutter(0.5);
	}

	&:deep(svg) {
		width: 273px;
		height: 150px;

		@include media-breakpoint-down(sm) {
			width: 102px;
			height: 56px;
		}
	}
}

.rate-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 34px;

	@include media-breakpoint-down(sm) {
		margin-top: 24px;
	}
}
</style>
